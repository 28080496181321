.bizOwner-bg-color {
    background-color: #00A3EF;
}

.book-img {
    max-width: 100vw;
    height: 190px;
    background-image: url('../../../../assets/images/babg_book_cover.jpg');
    background-size: contain;
    background-repeat: no-repeat;
    /* background-position: left center; */
}

.parent-row {
    position: relative;
}

.tools-column {
    position: absolute;
    top: 0;
    right: 0;

}

.bizOwner-headings {
    font-size: 24px;
    color: #00A3EF;
    line-height: 1.3;
    margin-bottom: 8px;
    font-weight: lighter;
}

.bizOwner-top-headings {
    color: #fff;
    font-size: 38px;
    max-width: 1140px;
    margin: 0 auto;
    line-height: 1;
    font-weight: normal;
}

.bizOwner-lead-heading {
    font-size: 22px;
    font-weight: lighter;

}

.bizOwner-parag {
    display: -webkit-box;
    -webkit-line-clamp: 3;
    height: 78px;
    -webkit-box-orient: vertical;
    overflow: hidden;
    font-size: 17px;
    line-height: 26px;
    margin-bottom: 0;
    font-weight: lighter;
}

.minute-parent {
    position: relative;
}

.minute-bg2 {
    background-color: #dedede;
    padding: 5px 2px;
    position: absolute;
    left: 0;
    top: 0;
}

.recent-article-img {
    position: relative;
    float: left;
    max-width: 100%;
    display: block;
    background-image: url('../../../../assets/industrySpecific/recent-articles.jpeg');
    background-size: cover;
    background-repeat: no-repeat;
    background-position: top center;

}

.restaurant-img {
    position: relative;
    float: left;
    max-width: 100%;
    display: block;
    background-image: url('../../../../assets/industrySpecific/restaurant-industry-q1-2022.jpg');
    background-size: cover;
    background-repeat: no-repeat;
    background-position: top center;

}

.rest-2022-img {
    position: relative;
    float: left;
    max-width: 100%;
    display: block;
    background-image: url('../../../../assets/industrySpecific/restaurant-industry-analysis.jpg');
    background-size: cover;
    background-repeat: no-repeat;
    background-position: top center;

}

.rest-2021-img {
    position: relative;
    float: left;
    max-width: 100%;
    display: block;
    background-image: url('../../../../assets/industrySpecific/CAT-bars-and-nightlife.jpeg');
    background-size: cover;
    background-repeat: no-repeat;
    background-position: top center;

}

.seven-key-img {
    position: relative;
    float: left;
    max-width: 100%;
    display: block;
    background-image: url('../../../../assets/industrySpecific/7-key-areas-to-evaluate-before-buying-a-restaurant.jpg');
    background-size: cover;
    background-repeat: no-repeat;
    background-position: top center;
}

.three-methods-img {
    position: relative;
    float: left;
    max-width: 100%;
    display: block;
    background-image: url('../../../../assets/industrySpecific/3-methods-of-how-to-finance-a-restaurant.jpg');
    background-size: cover;
    background-repeat: no-repeat;
    background-position: top center;
}

.buying-hotel-img {
    position: relative;
    float: left;
    max-width: 100%;
    display: block;
    background-image: url('../../../../assets/industrySpecific/TN_three-hotel-acquisition-tips.jpg');
    background-size: cover;
    background-repeat: no-repeat;
    background-position: top center;
}