.biz_owner_input_validation {
  border-top: 1px solid red;
  color: red;
}
.form-control-password-toggle {
  background-color: #f5f8fa;
  display: flex;
  position: relative;
  right: 5px;
  border-top-right-radius: 8px;
  border-bottom-right-radius: 8px;
}
